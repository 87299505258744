import React from 'react'
import { Link } from 'gatsby'

import {
  Grid,
  Box,
  Heading,
  Text,
  Image
} from 'theme-ui'

import logo from '../images/thrdhnd_logo.svg'

const Header = ({roleTitle}) => (
  <Grid
    sx={{
      justifyContent: 'center',
      pt: [5, null, 3],
      pb: [4, null, 3]
    }}>
    <Box sx={{textAlign: 'center'}} pt={4}>
      <Link
        to='/'
        style={{
          textDecoration: 'none'
        }}>
        <Heading
          color='offwhite'
          sx={{
            fontSize: [6, null, 8]
          }}>
          Third Hand
        </Heading>
      </Link>
    </Box>

    <Box sx={{textAlign: 'center'}}>
      <Heading
        sx={{
          color: 'red',
          display: 'inline-block',
          fontSize: [3, null, 5]
        }}>
        Web Dev
      </Heading>
      <Heading
        sx={{
          px: [3, null, 4],
          color: 'red',
          display: 'inline-block',
          fontSize: [3, null, 5]
        }}>
        Design
      </Heading>
      <Heading
        sx={{
          color: 'red',
          display: 'inline-block',
          fontSize: [3, null, 5]
        }}>
        Marketing
      </Heading>
    </Box>
    {/*
    <Box width={1} mb={5}>
      <Text color='dallas' fontSize={4}><Heading color='cashmere' fontSize={4} css={{display: 'inline-block'}}>Third Hand</Heading> is a genus of { roleTitle } based in Seattle, WA.</Text>
    </Box>
    */}
  </Grid>
)

export default Header
